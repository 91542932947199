import './main.scss';

import Countable from 'countable';

(function () {
    function CountableField(textarea) {
        var countDisplay = document.getElementById(textarea.id + "_counter");
        var countDown = false;
        var minCount, maxCount;
        if (textarea != null && countDisplay != null) {
            minCount = textarea.getAttribute("data-min-count");
            maxCount = textarea.getAttribute("data-max-count");

            Countable.on(textarea, updateCharatersLeft);
        }

        function updateCharatersLeft(counter) {
            var count = counter["all"];
            var left = maxCount - count;
            countDisplay.getElementsByClassName("text-count-current")[0].innerHTML = left;
            if (left < 0)
                countDisplay.className = "text-count text-is-over-max";
            else
                countDisplay.className = "text-count";
        }
    }

    function togglePasswordField() {
        const toggle = document.getElementById('id_password0_is_given');
        var pwField = document.getElementById('id_password1');

        if (!toggle) {
            return null;
        }

        if (toggle.checked) {
            pwField.parentElement.parentElement.className = 'field';
        } else {
            pwField.parentElement.parentElement.className = 'field is-hidden';
        }

        toggle.addEventListener('change', (event) => {
            if (event.target.checked) {
                pwField.parentElement.parentElement.className = 'field';
            } else {
                pwField.parentElement.parentElement.className = 'field is-hidden';
            }
        })

    }

    function togglePasswordLogin() {
        const toggle = document.getElementById('TogglePasswordLogin');
        const passwordForm = document.getElementById('PasswordLoginForm');

        toggle.addEventListener('click', (event) => {
            passwordForm.classList.toggle('is-hidden');
            toggle.classList.toggle('is-active');
        });
    }

    document.addEventListener('DOMContentLoaded', function (e) {
        [].forEach.call(document.querySelectorAll('[data-count]'), CountableField);
        togglePasswordField();
        togglePasswordLogin();
    })
})()
